import React from "react"

import fetch from "cross-fetch"

import { Grid, Box, Link } from "@material-ui/core"

import Section from "../sections/section"
import Button from "../modules/button"

const Build = () => {
  const netlify = {
    cenl: {
      hook: "60b4fda198de69d457790ba5",
      badge: "4a1f6162-8081-4844-aadc-d97a4da922ca",
    },
    pnal: {
      hook: "60b4fd619956e2925d858088",
      badge: "62e0853c-226c-4c9b-9759-3e566f207481",
    },
  }

  const triggerTitle = "Manual+build+from+website"
  const uri = `https://api.netlify.com/build_hooks/${
    netlify[process.env.GATSBY_PROJECT]?.hook
  }?trigger_title=${triggerTitle}`

  const [badge, setBadge] = React.useState({
    src: `https://api.netlify.com/api/v1/badges/${
      netlify[process.env.GATSBY_PROJECT]?.badge
    }/deploy-status`,
    hash: Date.now(),
    button: true,
  })

  const handleRefresh = () => {
    console.log("handleRefresh")
    setTimeout(() => {
      setBadge({
        ...badge,
        hash: Date.now(),
        button: false,
      })
    }, 500)
  }

  const handleBuild = () => {
    if (badge?.button) {
      console.log("handleBuild")

      fetch(uri, { method: "POST" })
        .then(res => {
          if (res.status >= 400) {
            throw new Error("Bad response from server")
          }
          return res.json()
        })
        .then(data => {
          console.log(data)
        })
        .catch(err => {
          console.error(err)
        })

      handleRefresh()
    }
  }

  return (
    <Section title="Build status" titleColor="DARK" background="LIGHT">
      <Box paddingY={4}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          {badge && (
            <Grid item>
              <Box textAlign="center">
                <img
                  src={`${badge.src}?${badge.hash}`}
                  alt=""
                  width={142}
                  height={22}
                />
                <br />
                <Link href="#" onClick={handleRefresh}>
                  refresh
                </Link>
              </Box>
            </Grid>
          )}

          {netlify[process.env.GATSBY_PROJECT]?.hook && (
            <Grid item>
              <Box onClick={handleBuild}>
                {/* <pre>{uri}</pre> */}
                <Button
                  linkLabel="BUILD NOW"
                  background="LIGHT"
                  disabled={badge?.button ? false : true}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Section>
  )
}

export default Build
